import React, { useEffect, useState } from "react"
import { PageProps } from "gatsby"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { SplitText } from "gsap/SplitText"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import "../../styles/arsenal.css"
import ProjectHero from "../../components/ProjectHero"
import ProjectTitle from "../../components/ProjectTitle"
import { StaticImage } from "gatsby-plugin-image"

const ArsenalPage = (props: PageProps) => {
    // GSAP
    gsap.config();
    gsap.registerPlugin(ScrollTrigger, SplitText);

    ScrollTrigger.defaults({ ignoreMobileResize: true });

    useEffect(() => {
        const slides = gsap.utils.toArray(".slide-section");
const getAngle = gsap.utils.random(-70, 70, 1, true);
const getScale = gsap.utils.random(0.05, 0.2, 0.05, true);
const tl = gsap.timeline({
  paused: true,
  defaults: {
    ease: "power3.in"
  },
  scrollTrigger: {
    trigger: "#main",
    start: "top top",
    end: "+=" + slides.length * 200 + "%",
    // markers: true,
    pin: true,
    scrub: 1.5
  }
});
slides.forEach((slide, index) => {
  const rotation = getAngle();
  const scale = getScale();
  gsap.set(slide, { rotation, scale, opacity: 0 });
  tl.to(slide, {
    duration: 0.5,
    ease: "power1.out",
    opacity: 1,
    delay: index > 0 ? 0.5 : 0
  }).to(slide, {
    duration: 1,
    rotation: 0,
    scale: 1
  });
});
// Add final pause
tl.to({}, {}, "+=1");



// One
gsap.to("#container .panel:not(:last-child)", {
    yPercent: -100, 
    ease: "none",
    stagger: 0.5,
    scrollTrigger: {
      trigger: "#container",
      start: "top top",
      end: "+=300%",
      scrub: true,
      pin: true
    }
  });
  
  gsap.set(".panel", {
    zIndex: (i, target, targets) => targets.length - i});

 
    let brandImagePin = document.querySelector("#container-2 .sticky-element");
    let brandImageNotPin = document.getElementById("container-2");
    
    ScrollTrigger.create({
      trigger: brandImagePin,
      start: "top top",
      end: () => `${brandImageNotPin.offsetHeight - brandImagePin.offsetHeight}px`,
      invalidateOnRefresh: true,
      pin: brandImagePin,
    });

gsap.utils.toArray("#container-2 p").forEach(target => {
    gsap.fromTo(
        target,
        { opacity: 0 },
        {
          opacity: 1,
          ease: "none",
          force3D: true,
          scrollTrigger: {
            trigger: target,
            start: 'top 100%',
            end: 'bottom center',
            pinType: "transform",
            scrub: 1.5,
          }
        }
      );
});

gsap.utils.toArray("#container-2 .asset").forEach(target => {
    gsap.fromTo(
        target,
        { width: "100vw" },
        { 
            width: "50vw",
            ease: "none",
            force3D: true,
            scrollTrigger: {
                trigger: target,
                start: 'top center',
                end: 'bottom center',
                // pinType: isTouch ? "fixed" : "transform",
                pinType: "transform",
                scrub: 1,
            }
        }
    )
});


const split = new SplitText(".split-text p", { type: "lines" });


let someDelay = gsap.delayedCall(0.2, resetGsapFunction).pause();
function windowResize() {
  someDelay.restart(true)
}
window.addEventListener("resize", windowResize);
function resetGsapFunction() {
  split.split();
  gsapFunction();
}
    

    gsapFunction();
    function gsapFunction() {
split.lines.forEach((target) => {
    gsap.to(target, {
      backgroundPositionX: 0,
      ease: "none",
      scrollTrigger: {
        trigger: target,
        scrub: 0.5,
        start: "top center",
        end: "bottom center"
      }
    });
  });

};

const heros = gsap.utils.toArray('.parallax');
heros.forEach((image) => {
    const asset = image.querySelector(".asset");

      gsap.to(asset, {
        y: () => asset.offsetHeight - image.offsetHeight,
        ease: "none",
        scrollTrigger: {
          trigger: image,
          scrub: true,
          pin: false,
          invalidateOnRefresh: true,
          onEnter: () => {
            resetGsapFunction();
          }
        },
      }); 
  });
// Two
gsap.utils.toArray("#container-4").forEach(section => {
    let tl = gsap.timeline({
        scrollTrigger: {
          trigger: section,
          start: "center center",
          // makes the height of the scrolling (while pinning) match the width, thus the speed remains constant (vertical/horizontal)
          end: () => "+=" + section.offsetHeight, 
          scrub: true,
          pin: true,
          anticipatePin: 1
        },
        defaults: {ease: "none"}
      });
    // animate the container one way...
    tl
      .fromTo(section.querySelector(".afterImage"), { yPercent: 100, y: 0}, {yPercent: 0})
      // ...and the image the opposite way (at the same time)
      .fromTo(section.querySelector(".afterImage .outer"), {yPercent: -100, y: 0}, {yPercent: 0}, 0)
    
      .fromTo(section.querySelector(".thirdImage"), { yPercent: 100, y: 0}, {yPercent: 0},1)
      // ...and the image the opposite way (at the same time)
      .fromTo(section.querySelector(".thirdImage .outer"), {yPercent: -100, y: 0}, {yPercent: 0}, 1);
  });

        return () => {

        };
    }, [])

    return (
        <Layout page="work" route="/portfolio/arsenal">
            <Seo
                image={'/arsenal/cover.jpg'}
                title="Arsenal Football Club by Alistair Bancroft"
                description={""}
            />
            <link href="https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap" rel="stylesheet" />
            <div className="arsenal-namespace">
                <ProjectHero
                    agency={'Inviqa'}
                    body={[
                        "",
                    ]}
                    engagement={'2022'}
                    position={'Front End Lead / Inviqa'}
                    project={{
                        label: 'ARSENAL FC',
                    }}
                    title={"Playground: Arsenal FC"}
                    url={{
                        href: 'https://www.arsenal.com/',
                        label: 'www.arsenal.com',
                    }}
                />

                {/* START */}


{/* 
<div className="w-full h-screen overflow-hidden flex" id="main">
  <div className="w-full h-screen bg-teal-600 text-white flex flex-col justify-center items-center px-16">
    <h1 className="text-4xl font-semibold mb-8">Rotation & Scale Slides</h1>
    <p className="text-2xl font-medium text-center">The example has 3 different slides that fade-in, then rotate and scale up and finally stay in the screen as the user scrolls down.</p>
  </div>
  <div className="slide-section w-full h-screen bg-orange-400 absolute inset-0" id="sectionOne">
  </div>
  <div className="slide-section w-full h-screen bg-emerald-400 absolute inset-0" id="sectionTwo">
  </div>
  <div className="slide-section w-full h-screen bg-blue-400 absolute inset-0" id="sectionThree"></div>
</div>
<div className="w-full h-screen bg-indigo-500"></div>
Two */}
{/* One */}
<div id="container" className="h-screen w-full">   
  <section className="panel absolute bg-orange-400 h-screen will-change-transform w-full">
    <div className="h-full w-full">
        <StaticImage
          placeholder="blurred"
          alt="Arsenal"
          className="h-full object-cover w-full"
          src="../../images/portfolio/arsenal/arsenal--banner-1.jpg"
        />
    </div>
  </section>
  <section className="panel absolute bg-emerald-400 h-screen will-change-transform w-full">
    <div className="h-full w-full">
        <StaticImage
          placeholder="blurred"
          alt="Arsenal"
          className="h-full object-cover w-full"
          src="../../images/portfolio/arsenal/arsenal--banner-2.jpg"
        />
    </div>
  </section>
  <section className="panel absolute bg-blue-400 h-screen will-change-transform w-full">
    <div className="h-full w-full">
        <StaticImage
          placeholder="blurred"
          alt="Arsenal"
          className="h-full object-cover w-full"
          src="../../images/portfolio/arsenal/arsenal--banner-3.jpg"
        />
    </div>
  </section>
</div>

<section className="flex h-[150vh] items-center justify-center overflow-hidden relative parallax">
    <div className="absolute bg-[#f1f2f3]/80 flex h-full items-center justify-center text-center w-full z-10 sticky-element">
        <div className="split-text w-full">
            <p className="font-bold italic mb-6 text-arsenal-primary text-[5vw] md:text-[3vw] uppercase max-w-[80vw] mx-auto">"the diversity in football teams I think is amazing, just amazing. I think Arsenal has always been good for that."</p>
        </div>
    </div>
    <div className="absolute -bottom-[20%] h-[150%] left-0 w-full asset">  
        <StaticImage
            placeholder="blurred"
            alt="Arsenal"
            className="h-full object-cover w-full"
            src="../../images/portfolio/arsenal/arsenal--banner-5.jpg"
        />
    </div>
</section>

<section id="container-2" className="w-full">   
    <div className="bg-black flex h-full min-h-screen relative">
        <div className="h-full relative w-1/2">
            <div className="asset h-full overflow-hidden sticky-element"  style={{ width: "100vw" }}>
                <StaticImage
                    placeholder="blurred"
                    alt="Arsenal"
                    className="h-screen object-cover w-screen"
                    src="../../images/portfolio/arsenal/arsenal--banner-4.jpg"
                />
            </div>
        </div>
        <div className="px-24 py-24 w-1/2">
            <div className="my-[120vh]">
                <p className="mb-6 text-white text-[1.4vw]">Eddie is in the minority in the current squad for never having played for a foreign team, even on loan, so has he learnt much foreign language in our dressing room?</p>
                <p className="mb-6 text-white text-[1.4vw]">“I’ve picked up a few words, I don’t know if they are all good though! Personally I want to learn a few more languages myself. When I get the time to do it I definitely want to study that.</p>
                <p className="mb-6 text-white text-[1.4vw]">“We learn a lot from each other’s backgrounds too,” he continues. “If I go to France for example, I’ll speak to Wilo, things like that. He tells me the places to see. I was asking Cedric about Portugal too recently, it’s always like that. We always help each other if anyone needs anything, the medical staff and backroom staff too. We have that kind of relationship. It makes it a much more enjoyable place to call work and to call home.”</p>
                <p className="mb-6 text-white text-[1.4vw]">Eddie says that the squad enjoy frequent team bonding exercises throughout the season, to help maintain that happy spirit, and bring everybody closer together. These can vary from team barbecues to group activities, though his favourite was a puzzle-based game all the players took part in last year.</p>
                <p className="mb-6 text-white text-[1.4vw]">“We've had a few good ones,” he smiles. “We went to Crystal Maze last season. It was a different experience for me, I haven't done something like that myself, so it was good to do, it was good fun and you get to spend time with your teammates not just at football. It gives people the chance to show a bit more of themselves, and it will help you express yourself, and that can translate to the pitch as well. I love those sort of things, I think all of us do.”</p>
            </div>
        </div>
    </div>
</section>
<section id="container-4" className="h-screen relative w-full">
    <div className="beforeImage h-full w-full">
        <StaticImage
            placeholder="blurred"
            alt="Arsenal"
            className="absolute h-full object-cover top-0 w-full"
            src="../../images/portfolio/arsenal/arsenal--banner-6.jpg"
        />
    </div>
    <div className="afterImage absolute h-full overflow-hidden top-0 w-full z-10" style={{ transform: "translate(0px, -100%)" }}>
        <div className="outer h-full w-full" style={{ transform: "translate(0px, 100%)", }}>
            <div className="inner h-full w-full">
                <StaticImage
                    placeholder="blurred"
                    alt="Arsenal"
                    className="absolute h-full object-cover top-0 w-full"
                    src="../../images/portfolio/arsenal/arsenal--banner-7.jpg"
                />
            </div>
        </div>
    </div>
    <div className="thirdImage absolute h-full overflow-hidden top-0 w-full z-20" style={{ transform: "translate(0px, -100%)" }}>
        <div className="outer h-full w-full" style={{ transform: "translate(0px, 100%)", }}>
            <div className="inner h-full w-full">
                <StaticImage
                    placeholder="blurred"
                    alt="Arsenal"
                    className="absolute h-full object-cover top-0 w-full"
                    src="../../images/portfolio/arsenal/arsenal--banner-8.jpg"
                />
            </div>
        </div>
    </div>
</section>


                {/* END */}
            </div>
        </Layout>
    )
}

export default ArsenalPage
